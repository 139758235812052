import "core-js/modules/es.number.constructor.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "order-box"
  }, [_c('div', {
    staticClass: "order-title"
  }, [_vm._v("我的订单")]), _c('div', {
    staticClass: "order-content"
  }, [_c('div', {
    staticClass: "order-table"
  }, [_c('div', {
    staticClass: "result-order-top"
  }, [_c('ul', {
    staticClass: "result-order-wrap"
  }, _vm._l(_vm.orderTypeList, function (item, index) {
    return _c('li', {
      key: index,
      on: {
        "click": function click($event) {
          return _vm.changeOrder(index);
        }
      }
    }, [_c('span', {
      class: {
        active: index === _vm.activeIdx
      }
    }, [_vm._v(_vm._s(item))])]);
  }), 0), !_vm.isShowSearch ? _c('img', {
    staticClass: "icon-search",
    attrs: {
      "src": require("./images/icon_search.png"),
      "alt": ""
    },
    on: {
      "click": _vm.changeShow
    }
  }) : _c('div', {
    staticClass: "search-box"
  }, [_c('img', {
    attrs: {
      "src": require("./images/icon_search.png"),
      "alt": ""
    }
  }), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.large,
      expression: "large"
    }],
    attrs: {
      "type": "text",
      "placeholder": "请输入"
    },
    domProps: {
      "value": _vm.large
    },
    on: {
      "keyup": function keyup($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.getOrderList.apply(null, arguments);
      },
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.large = $event.target.value;
      }
    }
  })])])]), _c('div', {
    staticClass: "product-title"
  }, [_c('ol', {
    staticClass: "product-wrap"
  }, [_c('li', [_c('span', [_c('div', {
    staticClass: "picker-time"
  }, [_c('span', [_vm._v("订单范围")]), _c('date-picker', {
    attrs: {
      "type": "month",
      "format": "YYYY-MM",
      "disabled-date": _vm.disabledDate,
      "placeholder": "请输入",
      "range": ""
    },
    on: {
      "input": _vm.handleSelect,
      "clear": _vm.handleClear
    },
    model: {
      value: _vm.time,
      callback: function callback($$v) {
        _vm.time = $$v;
      },
      expression: "time"
    }
  })], 1)])]), _vm._m(0), _vm._m(1), _vm._m(2), _vm._m(3), _vm._m(4)])]), _vm.list.length === 0 ? _c('div', {
    staticClass: "empty"
  }, [_vm._m(5)]) : _vm._e(), _vm._l(_vm.list, function (item, index) {
    return _c('div', {
      key: index,
      staticClass: "commodity-wrap"
    }, [_vm.list.length !== 0 ? _c('div', {
      staticClass: "commodity-title"
    }, [_c('div', {
      staticClass: "commodity-title-left"
    }, [_c('span', [_vm._v(_vm._s(item.orderAt))]), _c('p', [_vm._v(" 订单号: "), _c('span', [_vm._v(_vm._s(item.orderNumber))])])]), _c('div', {
      staticClass: "commodity-title-right"
    }, [item.companyName ? _c('p', [_vm._v(_vm._s(item.companyName))]) : _vm._e()])]) : _vm._e(), _c('div', {
      staticClass: "commodity-content"
    }, [_c('div', {
      staticClass: "commodity-list"
    }, [_c('div', {
      staticClass: "commodity-img-left"
    }, [_c('dl', [_c('dd', [_c('p', [_vm._v(" 普健ERP批发申请单号:" + _vm._s(item.erpApplicationFormId || " --") + " ")]), _c('p', [_vm._v("普健ERP批发销售单号:" + _vm._s(item.erpSaleOrderId || " --"))]), _c('p', [_vm._v(" 总品规数: "), _c('span', [_vm._v(_vm._s(item.categoryNumber))])]), _c('p', [_vm._v(" 总数量: "), _c('span', [_vm._v(_vm._s(item.totalNumber))])])])])]), _c('div', {
      staticClass: "commodity-consignee"
    }, [_vm._v(" " + _vm._s(item.userName) + " ")]), _c('div', {
      staticClass: "commodity-money"
    }, [item.realPayAmount != null ? _c('p', [_vm._v(" ¥ "), _c('span', [_vm._v(_vm._s(_vm._f("NumFormat")(Number(item.realPayAmount))))])]) : _c('p'), item.payType == 'ONLINE' ? _c('p', [_vm._v(_vm._s(_vm.payStatus[item.payStatus || "WAIT"]))]) : _vm._e(), item.payType == 'OFFLINE' ? _c('p', [_vm._v("其他方式支付")]) : _vm._e()]), _c('div', {
      staticStyle: {
        "width": "88px",
        "font-size": "14px",
        "padding-left": "15px"
      }
    }, [_vm._v(" " + _vm._s(_vm._f("payWay")(item.payWay || item.payType)) + " ")]), _c('div', {
      staticClass: "commodity-unpaid"
    }, [_c('p', {
      class: {
        active: item.orderStatus === '01' ? '' : 'active'
      }
    }, [_vm._v(" " + _vm._s(_vm._f("statusName")(item.orderStatus)) + " ")]), (item.orderStatus === '01' ? true : false) ? _c('p', [_vm._v(" 剩余 "), _c('span', {
      key: item.id
    }, [_c('countdown', {
      attrs: {
        "value": Math.floor(item.closeTime / 1000) + 1,
        "format": "h时m分s秒"
      },
      on: {
        "stop": _vm.stopEndTime
      }
    })], 1)]) : _vm._e()]), _c('div', {
      staticClass: "commodity-handle"
    }, [item.orderStatus === '01' ? _c('p', [_c('b-button', {
      attrs: {
        "type": "is-info is-small"
      },
      on: {
        "click": function click($event) {
          return _vm.handlePayment(item.orderNumber);
        }
      }
    }, [_vm._v("立即付款")])], 1) : _vm._e(), item.orderStatus === '03' ? _c('p', {
      on: {
        "click": function click($event) {
          return _vm.confirmOrder(item.orderNumber);
        }
      }
    }, [_vm._v(" 确认收货 ")]) : _vm._e(), item.orderStatus === '04' || item.orderStatus === '02' || item.orderStatus === '03' ? _c('p', {
      on: {
        "click": function click($event) {
          return _vm.againPurchase(item);
        }
      }
    }, [_vm._v(" 再次购买 ")]) : _vm._e(), item.orderStatus === '05' ? _c('p', {
      on: {
        "click": function click($event) {
          return _vm.anewPurchase(item);
        }
      }
    }, [_vm._v(" 重新购买 ")]) : _vm._e(), _c('p', {
      staticClass: "commodity-detail",
      on: {
        "click": function click($event) {
          return _vm.handleLink(item.orderNumber);
        }
      }
    }, [_vm._v(" 查看详情 ")]), item.orderStatus === '04' && item.invoiceStatus && item.invoiceStatus != 1 ? _c('p', {
      staticClass: "commodity-detail",
      on: {
        "click": function click($event) {
          return _vm.toInvoice(item.orderNumber);
        }
      }
    }, [_vm._v(" " + _vm._s(item.invoiceStatus == 2 ? "开票中" : item.invoiceStatus == 3 ? "查看发票" : "") + " ")]) : _vm._e(), item.orderStatus === '01' ? _c('p', {
      staticClass: "commodity-detail",
      on: {
        "click": function click($event) {
          return _vm.onCloseOrder(item.orderNumber);
        }
      }
    }, [_vm._v(" 取消订单 ")]) : _vm._e()])])])]);
  })], 2), _vm.list.length !== 0 ? _c('div', {
    staticClass: "pagination",
    staticStyle: {
      "width": "940px",
      "justify-content": "flex-end",
      "margin-left": "0px"
    }
  }, [_c('b-pagination', {
    staticClass: "is-centered",
    attrs: {
      "total": _vm.total,
      "current": _vm.current,
      "per-page": _vm.perPage,
      "range-before": _vm.rangeBefore,
      "range-after": _vm.rangeAfter
    },
    on: {
      "update:current": function updateCurrent($event) {
        _vm.current = $event;
      },
      "change": _vm.handleChange
    }
  })], 1) : _vm._e()]);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('li', [_c('span', [_vm._v("收货人")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('li', [_c('span', [_vm._v("金额")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('li', [_c('span', [_vm._v("支付方式")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('li', [_c('span', [_vm._v("状态")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('li', [_c('span', [_vm._v("操作")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "empty-list"
  }, [_c('img', {
    attrs: {
      "src": require("./images/pic_empty.png"),
      "alt": ""
    }
  }), _c('span', [_vm._v("暂无数据")])]);
}];
export { render, staticRenderFns };