import "core-js/modules/es.array.push.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/web.dom-collections.for-each.js";
import { orderTypeList } from "./order-mock";
import { Countdown } from "@components/countdown";
import { AccountStorage } from "@services/account";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/zh-cn";
var accountStorage = new AccountStorage();
var PayStatus = {
  WAIT: "待支付",
  PAYING: "支付中",
  SUCCESS: "支付成功",
  FAIL: "支付失败",
  CLOSED: "已取消",
  REFUNDED: "已退款"
};
export default {
  components: {
    Countdown: Countdown,
    DatePicker: DatePicker
  },
  data: function data() {
    return {
      payStatus: PayStatus,
      orderTypeList: orderTypeList,
      list: [],
      activeIdx: 0,
      large: "",
      //搜索输入框值
      ASC: true,
      total: null,
      current: 1,
      // 页码
      perPage: 10,
      rangeBefore: 3,
      rangeAfter: 1,
      status: "",
      isShowSearch: false,
      danger: false,
      dangerMessage: false,
      CategoryList: [],
      info: {
        Category: null
      },
      dates: [],
      // 时间选择
      CountDown: "",
      startTime: "",
      endTime: "",
      userId: "",
      time: null
    };
  },
  filters: {
    statusName: function statusName(type) {
      var statusObj = {
        "01": "待付款",
        "02": "待发货",
        "03": "待收货",
        "04": "交易完成",
        "05": "已取消"
      };
      return statusObj[type];
    },
    payWay: function payWay(type) {
      var statusObj = {
        ONLINE: "线上支付",
        ALIPAY: "支付宝支付",
        WXPAY: "微信支付",
        COD: "货到付款"
      };
      return statusObj[type];
    }
  },
  created: function created() {
    var newUserId = accountStorage.getCacheUserInfo();
    this.userId = newUserId.userId;
    // this.getTimeInterval();
    switch (this.$route.params.status) {
      case "01":
        this.activeIdx = 1;
        this.current = 1;
        this.status = this.$route.params.status;
        return this.getOrderList();
      case "02":
        this.activeIdx = 1;
        this.current = 1;
        this.status = this.$route.params.status;
        return this.getOrderList();
      case "03":
        this.activeIdx = 2;
        this.current = 1;
        this.status = this.$route.params.status;
        return this.getOrderList();
      case "04":
        this.activeIdx = 3;
        this.current = 1;
        this.status = this.$route.params.status;
        return this.getOrderList();
      case "05":
        this.activeIdx = 4;
        this.current = 1;
        this.status = this.$route.params.status;
        return this.getOrderList();
      // case "07":
      //   this.activeIdx = 5;
      //   this.current = 1;
      //   this.status = this.$route.params.status;
      //   return this.getOrderList();
      case "0":
        this.activeIdx = 0;
        this.current = 1;
        this.$route.params.status = "";
        return this.getOrderList();
    }
  },
  methods: {
    handleClear: function handleClear() {
      this.startTime = "";
      this.endTime = "";
      this.getOrderList();
    },
    // 搜索失焦
    blurInput: function blurInput() {
      this.isShowSearch = false;
    },
    disabledDate: function disabledDate(time) {
      return time.getTime() > Date.now() - 8.64e6; //设置选择今天以及今天之前的日期
    },
    // 倒计时结束
    stopEndTime: function stopEndTime() {
      this.getOrderList();
    },
    changeShow: function changeShow() {
      this.isShowSearch = !this.isShowSearch;
    },
    changeOrder: function changeOrder(idx) {
      this.activeIdx = idx;
      switch (idx) {
        case 0:
          this.status = "";
          this.current = 1;
          return this.getOrderList();
        case 1:
          this.status = "01"; //待付款
          this.current = 1;
          return this.getOrderList();
        case 2:
          this.status = "02"; //待发货
          this.current = 1;
          return this.getOrderList();
        case 3:
          this.status = "03"; //待收货
          this.current = 1;
          return this.getOrderList();
        case 4:
          this.status = "04"; //已完成
          this.current = 1;
          return this.getOrderList();
        case 5:
          this.status = "05"; //已取消
          this.current = 1;
          return this.getOrderList();
        // case 5:
        //   this.status = "07"; //发货中
        //   this.current = 1;
        //   return this.getOrderList();
      }
    },
    orderType: function orderType() {},
    handleChange: function handleChange(val) {
      this.current = val;
      this.getOrderList();
    },
    // 订单列表
    getOrderList: function getOrderList() {
      var _this = this;
      var data = {
        page: this.current,
        pageSize: this.perPage,
        status: this.status,
        name: this.large,
        startTime: this.startTime,
        endTime: this.endTime
      };
      var url = "/pjyy-deepexi-order-center/api/v1/order/OcOrder/orderList";
      this.$getHttpClient().post(url, data).then(function (res) {
        var content = res.data.data.content;
        _this.list = content;
        _this.total = res.data.data.totalElements;
        _this.perPage = res.data.data.size;
      });
    },
    // 确定收货
    confirmOrder: function confirmOrder(orderNumber) {
      var _this2 = this;
      var url = "/pjyy-deepexi-order-center/api/v1/order/OcOrder/updateOrderStatus04?orderNumber=".concat(orderNumber);
      this.$getHttpClient().get(url).then(function (res) {
        if (res.data.code === "200") {
          _this2.$buefy.toast.open({
            message: "操作成功",
            type: "is-success"
          });
          _this2.getOrderList();
        }
      });
    },
    // 重新购买
    anewPurchase: function anewPurchase(item) {
      var _this3 = this;
      var arr = [];
      item.orderLines.forEach(function (ele) {
        arr.push(ele.productId);
      });
      var url = "/pjyy-deepexi-order-center/api/v1/cart/againAddGoodsToCartList";
      var data = {
        orderNumber: item.orderNumber
      };
      this.$getHttpClient().post(url, data).then(function (res) {
        if (res.data.code === "200") {
          _this3.$buefy.toast.open({
            message: "操作成功",
            type: "is-success"
          });
          _this3.getOrderList();
          _this3.$router.push({
            path: "/cart/cartList"
          });
        }
      });
    },
    // 再次购买
    againPurchase: function againPurchase(item) {
      var _this4 = this;
      var arr = [];
      item.orderLines.forEach(function (ele) {
        arr.push(ele.productId);
      });
      var url = "/pjyy-deepexi-order-center/api/v1/cart/againAddGoodsToCartList";
      var data = {
        orderNumber: item.orderNumber
      };
      this.$getHttpClient().post(url, data).then(function (res) {
        if (res.data.code === "200") {
          _this4.$buefy.toast.open({
            message: "操作成功",
            type: "is-success"
          });
          _this4.getOrderList();
          _this4.$router.push({
            path: "/cart/cartList"
          });
        }
      });
    },
    //待付款取消订单
    onCloseOrder: function onCloseOrder(orderNumber) {
      var that = this;
      this.$buefy.dialog.confirm({
        message: "确定取消订单吗？",
        confirmText: "确定",
        cancelText: "取消",
        type: "is-info",
        onConfirm: function onConfirm() {
          var _this5 = this;
          var url = "/pjyy-deepexi-order-center/api/v1/order/OcOrder/cancelOrder?orderNumber=".concat(orderNumber);
          this.$getHttpClient().get(url).then(function (res) {
            if (res.data.code === "200") {
              _this5.$buefy.toast.open("操作成功");
              that.getOrderList();
            }
          });
        }
      });
    },
    // 取消订单
    updateOrderStatus: function updateOrderStatus(orderNumber) {
      var that = this;
      this.$buefy.dialog.confirm({
        message: "确定取消订单吗？",
        confirmText: "确定",
        cancelText: "取消",
        type: "is-info",
        onConfirm: function onConfirm() {
          var _this6 = this;
          var url = "/pjyy-deepexi-order-center/api/v1/order/OcOrder/updateOrderStatus05?orderNumber=".concat(orderNumber);
          this.$getHttpClient().get(url).then(function (res) {
            if (res.data.code === "200") {
              _this6.$buefy.toast.open("操作成功");
              that.getOrderList();
            }
          });
        }
      });
    },
    // 立即付款
    handlePayment: function handlePayment(orderNumber) {
      this.$router.push({
        name: "payMent",
        params: {
          orderId: orderNumber
        }
      });
    },
    formatDate: function formatDate(value) {
      var date = new Date(value);
      var y = date.getFullYear();
      var MM = date.getMonth() + 1;
      MM = MM < 10 ? "0" + MM : MM;
      var d = date.getDate() + 1;
      d = d < 10 ? "0" + d : d;
      var h = date.getHours();
      h = h < 10 ? "0" + h : h;
      var m = date.getMinutes();
      m = m < 10 ? "0" + m : m;
      var s = date.getSeconds();
      s = s < 10 ? "0" + s : s;
      return y + "-" + MM + "-" + d + " " + h + ":" + m + ":" + s;
    },
    formatDate1: function formatDate1(value) {
      var date = new Date(value);
      var y = date.getFullYear();
      var MM = date.getMonth() + 1;
      MM = MM < 10 ? "0" + MM : MM;
      var d = null;
      if (MM == 12) {
        d = date.getDate() + 1;
      } else {
        MM = date.getMonth() + 2;
        d = date.getDate();
      }
      // let d = date.getDate();
      d = d < 10 ? "0" + d : d;
      var h = date.getHours();
      h = h < 10 ? "0" + h : h;
      var m = date.getMinutes();
      m = m < 10 ? "0" + m : m;
      var s = date.getSeconds();
      s = s < 10 ? "0" + s : s;
      return y + "-" + MM + "-" + d + " " + "23:59:59";
    },
    parserDate: function parserDate(date) {
      var t = Date.parse(date);
      if (!isNaN(t)) {
        return new Date(Date.parse(date.replace(/-/g, "/")));
      }
    },
    // 下拉框
    handleSelect: function handleSelect(val) {
      // if (this.startTime) {
      //   return;
      // }
      this.current = 1;
      this.startTime = this.formatDate(val[0]).replace(/-/g, "/");
      this.endTime = this.formatDate1(val[1]).replace(/-/g, "/");
      this.getOrderList();
    },
    // 获取select
    getTimeInterval: function getTimeInterval() {
      var _this7 = this;
      var url = "/pjyy-deepexi-order-center/api/v1/order/OcOrder/timeInterval";
      this.$getHttpClient().get(url).then(function (res) {
        if (res.data.code === "200") {
          _this7.CategoryList = res.data.data;
        }
      });
    },
    // 查看详情
    handleLink: function handleLink(id) {
      this.$router.push({
        name: "orderDetail",
        params: {
          orderNumber: id
        }
      });
    },
    //查看发票
    toInvoice: function toInvoice(id) {
      this.$router.push({
        name: "electronicInvoice",
        query: {
          orderNumber: id
        }
      });
    },
    // 售后退货 暂时先屏蔽掉
    handleSaleLink: function handleSaleLink(id) {
      this.$router.push({
        name: "salesDetail",
        params: {
          status: "1",
          orderNumber: id
        }
      });
    }
  },
  updated: function updated() {}
};